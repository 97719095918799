<template>
  <CollapseSection
    id="group"
    :enable-collapse="false"
    :arr-sections="groupsArrToRendering"
    func-is-show-section>
    <template
      #header="{section:collection}">
      <GroupsHeader
        v-if="isLoadingHeader(collection) || (getListForListing(collection.mappingName).length || !isListingView)"
        :collection="collection"
        :is-listing-view="isListingView"
        :is-loading="isLoadingHeader(collection)"
        :is-need-show-switcher="isNeedShowSwitcher(collection)"
        :title="setExpandTitle(collection)"
        @changeCollectionsToShow="changeCollectionsToShow(collection)" />
    </template>
    <template
      #content="{section:collection}">
      <CollectionListing
        v-if="allowListingView"
        :collapsed-items-amount="getExpandedAmount(collection)"
        :collections-list="getListForListingByLength(collection)"
        :class="[getListForListing(collection.mappingName).length ? 'mt-2 mb-7' : 'ma-0']"
        :group="collection.group"
        :intersect="collection.intersect"
        :is-community-collections="true"
        :is-loading="isFetchingForGroup(collection.group)"
        :show-counter="collection.showCounter"
        :show-collapsed-items="showCollapsedItems(collection)"
        @onIntersect="$emit('onIntersect', {
          ...$event,
          collection,
        })"
        @click-action-menu="$emit('click-action-menu', {
          ...$event,
          collection,
        })" />
      <CollectionsTable
        v-else
        :collections-list="getListForListingByLength(collection)"
        :intersect="collection.intersect"
        :group="collection.group"
        hide-column-members
        @onIntersect="$emit('onIntersect', {
          ...$event,
          collection,
        })"
        @click-action-menu="$emit('click-action-menu', {
          ...$event,
          collection,
        })" />
    </template>
  </CollapseSection>
</template>
<script>
import CollapseSection from '@/components/App/AppCollapseSection';
import CollectionListing from '@/components/Listing/Collections';
import CollectionsTable from '@/components/Collections/CollectionsTable';
import GroupsHeader from './CommunityGroupsHeader';
import {
  NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW,
  SUGGESTIONS_GROUP,
  COMMUNITY_COLLECTIONS_GROUP,
  COMMUNITY_PAGES_GROUP,
  VERIFIED_WS_PAGES_GROUP,
} from '@/constants/collectionsList';
import { mapGetters } from 'vuex';
export default {
  name: 'CommunityGroups',
  components: {
    CollapseSection,
    CollectionListing,
    CollectionsTable,
    GroupsHeader,
  },
  props: {
    groupsArrToRendering: {
      type: Array,
      default: () => [],
    },
    isListingView: {
      type: Boolean,
      default: false,
    },
    dataStore: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      expandedCollections: {
      },
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useLazyLoading']),
    allowListingView() {
      const { isListingView } = this;
      return isListingView;
    },
  },
  methods: {
    getExpandedAmount({ expandedAmount = 0, rowsToShow = 0 } = {
    }) {
      if (expandedAmount) {
        return expandedAmount;
      }
      if (!rowsToShow) {
        return 0;
      }
      const { width } = this.$vuetify.breakpoint;
      if (width > 1110) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.BIGGEST * rowsToShow;
      }
      if (width > 867) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MIDDLE * rowsToShow;
      }
      if (width > 500) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_BIG * rowsToShow;
      }
      return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_SMALL * rowsToShow;
    },
    showCollapsedItems({ mappingName, showExpanded }) {
      return mappingName
          && showExpanded
          && !this.expandedCollections[mappingName];
    },
    getListForListing(name = '') {
      if (!name) return [];
      const { [name]: list } = this.dataStore ?? {
      };
      if (!list) return [];
      return list;
    },
    getListForListingByLength({ name = '', mappingName, showExpanded = false, rowsToShow = 0 } = {
    }) {
      if (!name) {
        return [];
      }
      const listToTrim = this.getListForListing(mappingName);
      if (!showExpanded || this.isListingView) return listToTrim;
      const length = this.getExpandedAmount({
        rowsToShow,
      });
      const isOpenedTab = this.expandedCollections[mappingName];
      const lengthToCut = length || listToTrim.length;
      if (isOpenedTab) {
        return listToTrim;
      }
      return [...listToTrim.slice(0, lengthToCut)];
    },
    changeCollectionsToShow({ mappingName }) {
      this.expandedCollections = {
        ...this.expandedCollections,
        [mappingName]: !this.expandedCollections[mappingName],
      };
    },
    setExpandTitle({ mappingName }) {
      return this.expandedCollections[mappingName] ?
        'collapsed view' : 'see all';
    },
    isNeedShowSwitcher({ showExpanded = false, mappingName, rowsToShow = 0 } = {
    }) {
      if (!showExpanded) return false;
      const { length } = this.getListForListing(mappingName) || {
      };
      return length > this.getExpandedAmount({
        rowsToShow,
      });
    },
    isFetchingForGroup(group) {
      if (!this.useLazyLoading) return false;
      const fetchingState = this.$store.state.Collections.isFetching;

      switch (group) {
      case SUGGESTIONS_GROUP:
        return fetchingState.collectionSuggestions;
      case COMMUNITY_COLLECTIONS_GROUP:
        return fetchingState.communityCollections;
      case COMMUNITY_PAGES_GROUP:
        return fetchingState.communityPages;
      case VERIFIED_WS_PAGES_GROUP:
        return fetchingState.verifiedWsPages;
      default:
        return false;
      }
    },
    isLoadingHeader(collection) {
      if (!this.useLazyLoading) return false;
      return this.isFetchingForGroup(collection.group) && !this.getListForListing(collection.mappingName).length;
    },
  },
};
</script>
