var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollapseSection',{attrs:{"id":"group","enable-collapse":false,"arr-sections":_vm.groupsArrToRendering,"func-is-show-section":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var collection = ref.section;
return [(_vm.isLoadingHeader(collection) || (_vm.getListForListing(collection.mappingName).length || !_vm.isListingView))?_c('GroupsHeader',{attrs:{"collection":collection,"is-listing-view":_vm.isListingView,"is-loading":_vm.isLoadingHeader(collection),"is-need-show-switcher":_vm.isNeedShowSwitcher(collection),"title":_vm.setExpandTitle(collection)},on:{"changeCollectionsToShow":function($event){return _vm.changeCollectionsToShow(collection)}}}):_vm._e()]}},{key:"content",fn:function(ref){
var collection = ref.section;
return [(_vm.allowListingView)?_c('CollectionListing',{class:[_vm.getListForListing(collection.mappingName).length ? 'mt-2 mb-7' : 'ma-0'],attrs:{"collapsed-items-amount":_vm.getExpandedAmount(collection),"collections-list":_vm.getListForListingByLength(collection),"group":collection.group,"intersect":collection.intersect,"is-community-collections":true,"is-loading":_vm.isFetchingForGroup(collection.group),"show-counter":collection.showCounter,"show-collapsed-items":_vm.showCollapsedItems(collection)},on:{"onIntersect":function($event){return _vm.$emit('onIntersect', Object.assign({}, $event,
        {collection: collection}))},"click-action-menu":function($event){return _vm.$emit('click-action-menu', Object.assign({}, $event,
        {collection: collection}))}}}):_c('CollectionsTable',{attrs:{"collections-list":_vm.getListForListingByLength(collection),"intersect":collection.intersect,"group":collection.group,"hide-column-members":""},on:{"onIntersect":function($event){return _vm.$emit('onIntersect', Object.assign({}, $event,
        {collection: collection}))},"click-action-menu":function($event){return _vm.$emit('click-action-menu', Object.assign({}, $event,
        {collection: collection}))}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }