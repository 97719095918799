<template>
  <fragment>
    <!-- Breadcrumbs component -->
    <div class="px-9 d-flex justify-space-between">
      <app-breadcrumbs
        :push-back-url="pushBackUrl"
        :breadcrumbs-list="breadcrumbs" />
      <app-switcher-to-listing />
    </div>
    <!-- Main content container -->
    <div class="library-page-container mb-12">
      <!-- Collections search component -->
      <CollectionsSearch
        class="mt-3"
        v-bind="propsDataForSearching" />

      <AppSort
        v-if="!searchProductsMode"
        wrapper-class="ml-auto"
        :default-item="defaultSortItem"
        :sort-items="COLLECTION_SORT_ITEMS"
        @resort="$emit('sortCommunity', $event)" />

      <AppEmptyList
        v-if="!isLoading && isEmpty && !searchProductsMode"
        class="pt-8"
        from="community" />

      <!-- Community groups component -->
      <CommunityGroups
        v-else
        v-show="!searchProductsMode"
        v-bind="propsDataToSections"
        :data-store="dataStore"
        :groups-arr-to-rendering="groupsArrToRendering"
        :is-listing-view="isListingView"
        @onIntersect="$emit('onIntersect', $event)"
        @click-action-menu="onClickActionMenu" />
    </div>
    <ProductDialog />
    <ShareAdvancedDialog
      :start-subscribe="true"
      :invite-to-text-type="COLLECTION"
      :item="activeCollection"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeCollection.id,
          },
          criteria: $event.criteria,
        })
      " />
    <Actions
      :show-activator="false"
      :item.sync="selectedItem"
      v-bind="actionsProps"
      :position-x="positionX"
      :position-y="positionY"
      is-community-collections
      :value.sync="showActionsCard"
      @manageActions="$emit('manageActions', $event)" />
  </fragment>
</template>
<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import { COLLECTION_SORT_ITEMS } from '@/constants/sortable';
// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import GetInfo from '@/mixins/getInfo';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
// components
import Actions from '@/components/Collections/CollectionsActions';
import AppBreadcrumbs from '@/components/App/AppBreadcrumbs';
import AppSort from '@/components/App/AppSort';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import CollectionsSearch from '@/components/Collections/CollectionsSearch';
import CommunityGroups from './CommunityGroups';
import ProductDialog
  from '@/components/Collections/CollectionsCreateProductDialog';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';
import { COLLECTION } from '@/constants/cores';
export default {
  name: 'CommunityLayout',
  components: {
    Actions,
    AppBreadcrumbs,
    AppSort,
    AppSwitcherToListing,
    CollectionsSearch,
    CommunityGroups,
    ProductDialog,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
    ShareAdvancedDialog,
  },
  mixins: [
    ToggleViewCondition,
    AppActionDotsMenu,
    GetInfo,
  ],
  props: {
    breadcrumbItems: {
      type: Object,
      default: () => {
      },
    },
    dataStore: {
      type: Object,
      default: () => {
      },
    },
    groupsArrToRendering: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    propsDataForSearching: {
      type: Object,
      default: () => {
      },
    },
    propsDataToSections: {
      type: Object,
      default: () => {
      },
    },
    sortCommunity: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      COLLECTION,
      COLLECTION_SORT_ITEMS,
      actionsProps: {
      },
    };
  },
  computed: {
    ...mapState('Collections', ['activeCollection', 'searchProductsMode']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserProfile', ['collectionSortingMode']),
    defaultSortItem() {
      return COLLECTION_SORT_ITEMS.find(item => item.sortingMode == this.collectionSortingMode) || COLLECTION_SORT_ITEMS[0];
    },
    // check if default community page is empty
    isEmpty() {
      const collectedArr = Object.values(this.dataStore).reduce((accumulator, arr) => {
        return [ ...accumulator, ...arr ];
      }, []);

      return collectedArr.length == 0;
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    pushBackUrl() {
      const { pushBackUrl } = this.breadcrumbItems || {
      };
      return pushBackUrl;
    },
    breadcrumbs() {
      const { breadcrumbs } = this.breadcrumbItems || {
      };
      return breadcrumbs;
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
    }),
    ...mapActions({
      manageSharedUser: 'Collections/manageSharedUser',
    }),
    async onClickActionMenu({ event, item, collection } = {
    }) {
      const {
        group,
        actionTypes: actionsConditions,
        showActions,
        kind = 'default',
      } = collection ?? {
      };
      this.setMenuItem({
        item,
        event,
      });
      this.actionsProps = {
        group,
        actionsConditions,
        showActions,
        kind,
      };
    },
  },
};
</script>
