<template>
  <div
    :style="isListingView ? 'font-size: 24px' : ''"
    class="d-flex justify-space-between">
    <CollectionsListingHeaderSkeleton
      v-if="isLoading && isListingView" />
    <template v-else>
      <div>
        {{ collection.useAlternativeText ? collection.alternativeText : collection.name }}
        <template v-if="getSafe(collection.headerIcon, 'name')">
          <v-icon
            :size="!isListingView ? 18 : 24"
            v-bind="getProps(collection.headerIcon, ['name'])">
            {{ getSafe(collection.headerIcon, 'name') }}
          </v-icon>
        </template>
      </div>
      <p
        v-if="isNeedShowSwitcher"
        class="mb-0 d-flex align-center"
        @click="$emit('changeCollectionsToShow')">
        <a class="text-uppercase">
          {{ title }}
        </a>
      </p>
    </template>
  </div>
</template>
<script>
import CollectionsListingHeaderSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingHeaderSkeleton';
import PropsUtils from '@/mixins/PropsUtils';
export default {
  name: 'CommunityGroupsHeader',
  components: {
    CollectionsListingHeaderSkeleton,
  },
  mixins: [PropsUtils],
  props: {
    collection: {
      type: Object,
      default: () => {
      },
    },
    isNeedShowSwitcher: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isListingView: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
